import React, { useEffect, useState } from "react"
import KeycloakService from "services/KeycloakService"
import { useLocation, Link } from "react-router-dom"
import { deleteStartUp, fetchStartUps } from "api/startup"
import RightArrowIcon from "images/right-arrow-icon.svg"
import PencilIcon from "images/pencil-icon.svg"
import TrashIcon from "images/trash-icon.svg"
import SideBar from "components/Elements/SideBar/SideBar"
import roles from "components/utils/roles"
import AddStartupButton from "images/add-icon.svg"
import "./StartUps.css"

const StartUps = () => {
  const { isLoggedIn, hasRole } = KeycloakService
  const location = useLocation()
  const programName = location.pathname.split("/")[1]
  const [startUps, setStartUps] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: startUpData } = await fetchStartUps()
        const startUpForProgram = []

        if (startUpData.length) {
          startUpData.forEach((startUp) => {
            if (startUp.program.slug === programName) {
              startUpForProgram.push(startUp)
            }
          })

          setStartUps(startUpForProgram)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [programName])

  const handleDeleteStartUp = async (startUpId) => {
    const confirmDelete = window.confirm(
      `"${
        startUps.find((startUp) => startUp.id === startUpId).name
      }", startup ​​portföyünden silmek istediğinizden emin misiniz?`
    )
    if (confirmDelete) {
      try {
        await deleteStartUp(startUpId)
        const arr = startUps.filter((startUp, _idx) => startUp.id !== startUpId)
        setStartUps(arr)
        alert(
          `"${
            startUps.find((startUp) => startUp.id === startUpId).name
          }", startup ​​portföyünden başarıyla silindi.`
        )
      } catch (error) {
        alert(error)
      }
    }
  }

  return (
    <div className="start-ups-container">
      <SideBar programName={programName} />
      <div className="start-up-title-container">
        <h1 className="start-up-title">Start-up Portföyü</h1>
        {isLoggedIn && hasRole([roles.ADMIN]) && (
          <Link to="startup-register">
            <img
              src={AddStartupButton}
              alt="add-startup"
              className="add-startup-button"
            />
          </Link>
        )}
      </div>
      <div className="start-up-content">
        {startUps.length > 0 &&
          startUps.map((startUp, index) => (
            <div key={index} className="start-up-container">
              <Link
                to={`start-up-portfoyu/${startUp.slug}`}
                className="start-up-wrapper"
              >
                <div className="start-up-logo-wrapper">
                  <img
                    className="start-up-logo"
                    src={startUp.logo?.url}
                    alt={startUp.name}
                  />
                </div>
                <div className="start-up-details">
                  <div className="content-details">
                    <h2 className="start-up-name">{startUp.name}</h2>
                    <p className="start-up-category">
                      {startUp.category || "Ed-Tech"}
                    </p>
                  </div>
                  <div className="icons-wrapper">
                    {!hasRole([roles.ADMIN]) && (
                      <img
                        className="icon"
                        src={RightArrowIcon}
                        alt={"arrow-icon"}
                      />
                    )}
                  </div>
                </div>
              </Link>

              {hasRole([roles.ADMIN]) && (
                <div className="icons-wrapper" style={{ paddingRight: "15px" }}>
                  <Link to={`start-up-portfoyu/${startUp.slug}/edit`}>
                    <img className="arrow-icon" src={PencilIcon} alt={"edit-icon"} />
                  </Link>
                  <img
                    className="icon"
                    src={TrashIcon}
                    alt={"delete-icon"}
                    onClick={() => {
                      handleDeleteStartUp(startUp.id)
                    }}
                  />
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default StartUps
