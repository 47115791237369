import React, { useState, useEffect } from "react"
import FeedbackBox from "./FeedbackBox"
import { fetchFeedback } from "api/feedback"
import { useLocation, Link } from "react-router-dom"
import SideBar from "components/Elements/SideBar/SideBar"

import "./FeedbackDetails.css"

const FeedbackDetails = () => {
  const [feedback, setFeedback] = useState([])
  const location = useLocation()

  const programName = location.pathname.split("/")[1]
  const startUpName = location.pathname.split("/")[3]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: feedbacks } = await fetchFeedback()

        if (feedbacks.length) {
          const startupFeedbacks = feedbacks.filter(
            (feed) => feed.startup.slug === startUpName
          )
          setFeedback(startupFeedbacks)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [startUpName])

  return (
    <div className="feedback-details-container">
      <SideBar programName={programName} />
      <div className="feedback-details-title-container">
        <h1 className="feedback-details-title">Geri Dönüşler</h1>
      </div>

      <div className="feedback-boxes-container">
        {feedback.map((feed, index) => (
          <Link
            to={`${startUpName}/${feed.name}`}
            className="feedback-boxes-wrapper"
            key={index}
          >
            <FeedbackBox
              name={feed.name}
              surname={feed.surname}
              email={feed.email}
              phoneNumber={feed.phone}
              dateTime={feed.updated_at}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}

export default FeedbackDetails
