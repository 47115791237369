import { cms } from "./index"

export function fetchStartUps() {
  return cms.get(`arinkom-startups?_sort=order:ASC`)
}

export function fetchStartUp(startupName) {
  return cms.get(`arinkom-startups?slug=${startupName}`)
}

export function registerStartUp(formData) {
  return cms.post("arinkom-startups", formData)
}

export async function fetchStartUpsCount() {
  return cms.get(`arinkom-startups/count`)
}

export async function deleteStartUp(startupId) {
  return cms.delete(`/arinkom-startups/${startupId}`)
}

export async function updateStartUp(startupId, formData) {
  return cms.put(`/arinkom-startups/${startupId}`, formData)
}
