import React from 'react'
import PropTypes from 'prop-types'
import RightArrowIcon from 'images/right-arrow.svg';
import "./Header.css";

const Header = ({ startUp }) => {
  return (
    <>
      <div className="app-header">
        <img className="back-icon" src={RightArrowIcon} alt="back-icon" onClick={() => window.history.back()} />
      </div>
      <div className="app-header-logo-wrapper">
        {startUp.logo && <img className="app-header-logo" src={startUp.logo.url} alt="logo-icon" />}
      </div>
    </>
  )
}

Header.propTypes = {
  startUp: PropTypes.object
}

export default Header