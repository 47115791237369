import React from "react"
import { useLocation } from "react-router-dom"
import SideBar from "components/Elements/SideBar/SideBar"
import "./HomePage.css"

const HomePage = () => {
  const location = useLocation()
  const programName = location.pathname.split("/")[1]
  const scheduleData = [
    {
      title: "Açılış Konuşmaları Program Tanıtımı",
      description: `Mehmet Hakan DAĞ - ARİNKOM TTO Yöneticisi
        Prof. Dr. Ali Resul Usul Anadolu Üniversitesi Rektör Yardımcısı`,
      timeFrom: "14:00",
      timeTo: "14:10",
    },
    {
      title: "Yatırımcı - Girişimci Paneli",
      description: `Hakan YILDIZ - Fonbulucu/ReINVeS Capital - CEO
        Doç Dr. İbrahim Şevki BAYRAKDAR - Cronia Catch - Kurucu`,
      timeFrom: "14:10",
      timeTo: "14:40",
    },
    {
      title: "Girişimci Sunumları",
      description: `Thoufeeque SAHEER - Archi’s Academy
        Halil KÖSE - Hastanem Yanımda
        Mustafa Y. GÜL - Minderler Adası
        Kıvanç Mert AĞIÇ - Bronco
        Nilgün ÇAĞLARIRMAK USLU - EMLT+AI`,
      timeFrom: "14:40",
      timeTo: "15:30",
    },
    {
      title: "Ara",
      timeFrom: "15:30",
      timeTo: "15:50",
    },
    {
      title: "Girişimci Sunumları",
      description: `İbrahim Şevki BAYRAKDAR - Cranio Catch
        Asiye MERİÇ - ProDrug
        Gökhan YAĞCI - Genoride
        Muhammed Said ÖZER - Atlas DC
        Önder EKERİM - Sentlnel`,
      timeFrom: "15:50",
      timeTo: "16:40",
    },
    {
      title: "Kokteyl",
      timeFrom: "16:40",
      timeTo: "17:30",
    },
  ]

  return (
    <div className="schedule-container">
      <SideBar programName={programName} />
      <div className="schedule-title-container">
        <h1 className="schedule-page-title">
          ARİNKOM TTO
          <br />
          TECHUP+ İSTANBUL <br /> DEMO DAY PROGRAMI <br /> 29-03-2022
        </h1>
      </div>
      <div className="schedule-content">
        <div className="schedule-section">
          {scheduleData.map((item, index) => (
            <div className="schedule-group" key={index}>
              <div className="time-line-section">
                <p className="scheduled-time">{`${item.timeFrom}-${item.timeTo}`}</p>
              </div>
              <div className="time-line-detailed-section">
                <p className="schedule-title">{item.title}</p>
                {item.description && (
                  <p className="schedule-sub-text">{item.description}</p>
                )}
              </div>
            </div>
          ))}
        </div>

        <button className="startup-button">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/${programName}/start-up-portfoyu`}
            className="startup-button-link"
          >
            Start-up Portföyü
          </a>
        </button>
      </div>
    </div>
  )
}

export default HomePage
