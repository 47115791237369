import React, { useEffect, useState } from "react"
import SuccesIcon from "images/success-icon.svg"
import LeftArrowIcon from "images/left-arrow.svg"
import "./ConnectForm.css"
import { fetchStartUp } from "api/startup"
import { useLocation } from "react-router-dom"
import Footer from "components/Elements/Footer/Footer"
import Header from "components/Elements/Header/Header"
import { sendForm } from "api/feedback"

const FormCompleteDialog = ({ programName }) => {
  return (
    <div className="form-complete-dialog-box">
      <h1 className="success-text">Anketiniz tarafımıza başarıyla ulaşmıştır!</h1>
      <img className="success-logo" src={SuccesIcon} alt="success icon" />
      <p className="return-to-portfolio-text">Portföye geri dön</p>
      <button
        className="go-back-button"
        onClick={() =>
          (window.location.href = `${process.env.REACT_APP_BASE_URL}/${programName}/start-up-portfoyu`)
        }
      >
        <img className="back-icon" src={LeftArrowIcon} alt="back" />
      </button>
    </div>
  )
}

const ConnectForm = () => {
  const location = useLocation()

  const programName = location.pathname.split("/")[1]
  const startUpName = location.pathname.split("/")[3]
  const [startUp, setStartUp] = useState({})
  const [formIsComplete, setFormIsComplete] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    company: "",
    email: "",
    phone: "",
    startup: null,
    suggestions: "",
    wantsToInvest: false,
    wantsToBuyProduct: false,
    wantsToMentor: false,
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const { name, surname, company, email, phone } = formData
      localStorage.setItem(
        "formData",
        JSON.stringify({
          name,
          surname,
          company,
          email,
          phone,
        })
      )

      await sendForm(formData)
      setFormIsComplete(true)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const localFormData = localStorage.getItem("formData")
      const { data } = await fetchStartUp(startUpName)

      if (data) {
        setFormData({ ...JSON.parse(localFormData), startup: data[0].id })
        setStartUp(data[0])
      }
    }
    fetchData()
  }, [startUpName])

  return (
    <>
      <div className="start-ups-container">
        <Header startUp={startUp} />
        {!formIsComplete && (
          <div className="form-page-section">
            <div>
              <p className="title">İş Birliği Anketi</p>
            </div>
            <div>
              <form onSubmit={handleSubmit}>
                <div className="form-inputs">
                  <label className="form-label">İsim:</label>
                  <input
                    required
                    type="text"
                    className="form-input form-input--short-text"
                    value={formData.name}
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value })
                    }}
                  />
                </div>
                <div className="form-inputs">
                  <label className="form-label">Soyisim:</label>
                  <input
                    required
                    type="text"
                    className="form-input form-input--short-text"
                    value={formData.surname}
                    onChange={(e) => {
                      setFormData({ ...formData, surname: e.target.value })
                    }}
                  />
                </div>
                <div className="form-inputs">
                  <label className="form-label">Firma - Kurum:</label>
                  <input
                    required
                    type="text"
                    className="form-input form-input--short-text"
                    value={formData.company}
                    onChange={(e) => {
                      setFormData({ ...formData, company: e.target.value })
                    }}
                  />
                </div>
                <div className="form-inputs">
                  <label className="form-label">E-posta:</label>
                  <input
                    required
                    type="email"
                    className="form-input form-input--short-text"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value })
                    }}
                  />
                </div>
                <div className="form-inputs">
                  <label className="form-label">Telefon:</label>
                  <input
                    required
                    type="tel"
                    className="form-input form-input--short-text"
                    value={formData.phone}
                    onChange={(e) => {
                      setFormData({ ...formData, phone: e.target.value })
                    }}
                  />
                </div>
                <div className="form-inputs-checkbox">
                  <label className="form-label-checkbox">
                    Yatırım yapmak istiyorum
                  </label>
                  <input
                    type="checkbox"
                    className="form-check-box"
                    value={formData.wantsToInvest}
                    onChange={(e) => {
                      setFormData({ ...formData, wantsToInvest: e.target.checked })
                    }}
                  />
                </div>
                <div className="form-inputs-checkbox">
                  <label className="form-label-checkbox">
                    Ürün ya da hizmet almak istiyorum
                  </label>
                  <input
                    type="checkbox"
                    className="form-check-box"
                    value={formData.wantsToBuyProduct}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        wantsToBuyProduct: e.target.checked,
                      })
                    }}
                  />
                </div>
                <div className="form-inputs-checkbox">
                  <label className="form-label-checkbox">
                    Destek / mentörlük vermek istiyorum
                  </label>
                  <input
                    type="checkbox"
                    className="form-check-box"
                    value={formData.wantsToMentor}
                    onChange={(e) => {
                      setFormData({ ...formData, wantsToMentor: e.target.checked })
                    }}
                  />
                </div>
                <div className="form-inputs">
                  <label className="form-label">Görüş ve önerileriniz</label>
                  <textarea
                    required
                    className="form-input form-input--text-area"
                    value={formData.suggestions}
                    rows="10"
                    onChange={(e) => {
                      setFormData({ ...formData, suggestions: e.target.value })
                    }}
                  />
                </div>
                <input
                  type="submit"
                  value="Gönder"
                  className="form-input form-input--send-button"
                />
              </form>
            </div>
          </div>
        )}
        {formIsComplete && <FormCompleteDialog programName={programName} />}
        <Footer startUp={startUp} />
      </div>
    </>
  )
}

export default ConnectForm
