import React, { useState, useEffect } from "react"
import { fetchProgram } from "api/program"
import { useLocation } from "react-router-dom"
import SideBar from "components/Elements/SideBar/SideBar"
import Footer from "components/Elements/Footer/Footer"
import Upload from "images/upload.svg"
import slugify from "slugify"
import {
  fetchStartUp,
  fetchStartUpsCount,
  registerStartUp,
  updateStartUp,
} from "api/startup"

import "./StartUpRegisterForm.css"
import { uploadFile } from "api/uploadFile"

const StartUpRegisterForm = ({ isEdit = false }) => {
  const location = useLocation()
  const [program, setProgram] = useState({})
  const [logo, setLogo] = useState()
  const [companyEvents, setCompanyEvents] = useState()
  const [companyServiceImage, setCompanyServiceImage] = useState()

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    logo: null,
    category: "",
    linkedIn: "",
    facebook: "",
    instagram: "",
    website: "",
    financialNeeds: "",
    incomeModel: "",
    teamMembers: "",
    problem: "",
    solution: "",
    events: null,
    contact: "",
    targetMarket: "",
    customers: "",
    competitors: "",
    productServiceImage: null,
  })

  const programName = location.pathname.split("/")[1]
  const startUpSlug = location.pathname.split("/")[3]

  const uploadLogoImage = async (logoImage) => {
    const data = new FormData()
    data.append("files", logoImage[0])
    const { data: logoFile } = await uploadFile(data)
    setFormData({ ...formData, logo: logoFile[0].id })
  }

  const uploadCompanyServiceImage = async (serviceImage) => {
    const data = new FormData()
    data.append("files", serviceImage[0])
    const { data: serviceImageFile } = await uploadFile(data)
    setFormData({ ...formData, productServiceImage: serviceImageFile[0].id })
  }

  const uploadEvents = async (eventImages) => {
    const data = new FormData()
    const arr = [...eventImages]
    arr.forEach((file, index) => {
      data.append(`files`, file, `file-${index}`)
    })
    try {
      const { data: achfile } = await uploadFile(data)
      setFormData({ ...formData, events: achfile })
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!isEdit) {
      const { data: startUpCount } = await fetchStartUpsCount()
      const order = +startUpCount + 1
      const slug = slugify(formData.name, { lower: true })
      setFormData({ ...formData, order: +order, slug: slug })

      try {
        await registerStartUp({
          ...formData,
          order: +order,
          slug: slug,
          program: program.id,
        })
        window.location.href = `${process.env.REACT_APP_BASE_URL}/${programName}/start-up-portfoyu`
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        const { data: startUpId } = await fetchStartUp(startUpSlug)
        await updateStartUp(startUpId[0].id, formData)
        window.location.href = `${process.env.REACT_APP_BASE_URL}/${programName}/start-up-portfoyu`
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: programData } = await fetchProgram(programName)

        if (programData.length) {
          setProgram(programData[0])
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [programName])

  useEffect(() => {
    const fetchData = async () => {
      const { data: startUp } = await fetchStartUp(startUpSlug)
      setFormData(...startUp)
      setLogo(startUp[0].logo?.url)
      setCompanyServiceImage(startUp[0].productServiceImage?.url)
      setCompanyEvents(startUp[0].events)
    }
    if (isEdit) {
      fetchData()
    }
  }, [isEdit, startUpSlug])

  return (
    <div className="start-up-register-form-root">
      <SideBar programName={programName} />
      <div className="start-up-title-container">
        <h1 className="start-up-title">Start-up Bilgileri</h1>
      </div>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-inputs">
            <label className="form-label img-upload-container">
              Şirket Logosu:
              {logo ? (
                <span>
                  <img
                    src={logo}
                    alt="logo"
                    className="uploaded-img"
                    onClick={() => {
                      setFormData({ ...formData, logo: null })
                      setLogo(null)
                    }}
                  />
                </span>
              ) : (
                <label htmlFor="logo-upload-button">
                  <img src={Upload} alt="logo" className="register-image-upload" />
                </label>
              )}
            </label>
            <input
              accept="image/*"
              type="file"
              className="image-upload-dialog"
              id={`logo-upload-button`}
              onChange={(e) => {
                setLogo(URL.createObjectURL(e.target.files[0]))
                uploadLogoImage(e.target.files)
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Şirket İsmi:</label>
            <input
              required
              type="text"
              className="form-input form-input--short-text"
              value={formData.name}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Alan:</label>
            <input
              required
              type="text"
              className="form-input form-input--short-text"
              value={formData.category}
              onChange={(e) => {
                setFormData({ ...formData, category: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Şirket Bilgisi:</label>
            <textarea
              required
              rows="10"
              className="form-input form-input--textarea"
              value={formData.description}
              onChange={(e) => {
                setFormData({ ...formData, description: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Web Sayfası:</label>
            <input
              required
              type="text"
              className="form-input form-input--short-text"
              value={formData.website}
              onChange={(e) => {
                setFormData({ ...formData, website: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Kurucu Ekip:</label>
            <textarea
              required
              rows="10"
              className="form-input form-input--textarea"
              value={formData.teamMembers}
              onChange={(e) => {
                setFormData({ ...formData, teamMembers: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Finansman İhtiyacı:</label>
            <textarea
              required
              rows="10"
              className="form-input form-input--textarea"
              value={formData.financialNeeds}
              onChange={(e) => {
                setFormData({ ...formData, financialNeeds: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label img-upload-container">
              Başarılar:
              <span>
                <label htmlFor="events-upload-button">
                  <img src={Upload} alt="logo" className="register-image-upload" />
                </label>
              </span>
            </label>
            {companyEvents && (
              <div className="uploaded-imgs-container">
                {Array.from(companyEvents).map((event, index) => (
                  <img
                    key={index}
                    src={event?.url || URL.createObjectURL(event)}
                    alt="logo"
                    className="uploaded-img"
                  />
                ))}
              </div>
            )}
            <input
              multiple
              accept="image/*"
              type="file"
              className="image-upload-dialog"
              id={`events-upload-button`}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  events: e.target.files,
                })
                uploadEvents(e.target.files)
                setCompanyEvents(e.target.files)
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">İletişm:</label>
            <textarea
              required
              rows="5"
              className="form-input form-input--textarea"
              value={formData.contact}
              onChange={(e) => {
                setFormData({ ...formData, contact: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label img-upload-container">
              Ürün Görseli:
              {companyServiceImage ? (
                <span>
                  <img
                    src={companyServiceImage}
                    alt="product service"
                    className="uploaded-img"
                    onClick={() => {
                      setFormData({ ...formData, productServiceImage: null })
                      setCompanyServiceImage(null)
                    }}
                  />
                </span>
              ) : (
                <label htmlFor="product-service-image-upload-button">
                  <img
                    src={Upload}
                    alt="product service"
                    className="register-image-upload"
                  />
                </label>
              )}
            </label>
            <input
              accept="image/*"
              type="file"
              className="image-upload-dialog"
              id={`product-service-image-upload-button`}
              onChange={(e) => {
                setCompanyServiceImage(URL.createObjectURL(e.target.files[0]))
                uploadCompanyServiceImage(e.target.files)
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Problem:</label>
            <textarea
              required
              rows="15"
              className="form-input form-input--textarea"
              value={formData.problem}
              onChange={(e) => {
                setFormData({ ...formData, problem: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Çözüm:</label>
            <textarea
              required
              rows="15"
              className="form-input form-input--textarea"
              value={formData.solution}
              onChange={(e) => {
                setFormData({ ...formData, solution: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Gelir Modeli:</label>
            <textarea
              required
              rows="10"
              className="form-input form-input--textarea"
              value={formData.incomeModel}
              onChange={(e) => {
                setFormData({ ...formData, incomeModel: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Hedef Pazar:</label>
            <textarea
              required
              rows="10"
              className="form-input form-input--textarea"
              value={formData.targetMarket}
              onChange={(e) => {
                setFormData({ ...formData, targetMarket: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Müsteriler:</label>
            <textarea
              required
              rows="10"
              className="form-input form-input--textarea"
              value={formData.customers}
              onChange={(e) => {
                setFormData({ ...formData, customers: e.target.value })
              }}
            />
          </div>
          <div className="form-inputs">
            <label className="form-label">Rekabet:</label>
            <textarea
              required
              rows="6"
              className="form-input form-input--textarea"
              value={formData.competitors}
              onChange={(e) => {
                setFormData({ ...formData, competitors: e.target.value })
              }}
            />
          </div>
          <input
            type="submit"
            value="Kaydet"
            className="form-input form-input--send-button"
          />
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default StartUpRegisterForm
