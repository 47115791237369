import React, { useEffect, useState } from "react"
import { useLocation, Link } from "react-router-dom"
import { fetchStartUps } from "api/startup"
import RightArrowIcon from "images/right-arrow-icon.svg"
import SideBar from "components/Elements/SideBar/SideBar"

import "./Feedback.css"

const Feedback = () => {
  const location = useLocation()
  const programName = location.pathname.split("/")[1]
  const [startUps, setStartUps] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: startUpData } = await fetchStartUps()
        const startUpForProgram = []

        if (startUpData.length) {
          startUpData.forEach((startUp) => {
            if (startUp.program.slug === programName) {
              startUpForProgram.push(startUp)
            }
          })

          setStartUps(startUpForProgram)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [programName])

  return (
    <div className="feedback-container">
      <SideBar programName={programName} />
      <div className="feedback-title-container">
        <h1 className="feedback-title">Geri Dönüşler</h1>
      </div>

      <div className="start-up-content">
        {startUps.length > 0 &&
          startUps.map((startUp, index) => (
            <Link
              to={`geri-donusler/${startUp.slug}`}
              className="start-up-wrapper"
              key={index}
            >
              <div className="start-up-logo-wrapper">
                <img
                  className="start-up-logo"
                  src={startUp.logo.url}
                  alt={startUp.name}
                />
              </div>
              <div className="start-up-details">
                <div className="content-details">
                  <h2 className="start-up-name">{startUp.name}</h2>
                  <p className="start-up-category">
                    {startUp.category || "Ed-Tech"}
                  </p>
                </div>
                <img
                  className="arrow-icon"
                  src={RightArrowIcon}
                  alt={"arrow-icon"}
                />
              </div>
            </Link>
          ))}
      </div>
    </div>
  )
}

export default Feedback
