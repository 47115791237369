import React, { useState } from "react"
import KeycloakService from "services/KeycloakService";
import HamburgerMenu from "images/hamburger-menu.svg"
import HamburgerMenuLight from "images/hamburger-menu-light.svg"
import AnacLogo from "images/anac-logo.png"
import ArinkomLogo from "images/arinkom-logo.svg"
import CloseIcon from "images/close-icon.svg"
import roles from "components/utils/roles"
import "./SideBar.css"

const SideBar = ({ programName, isDarkMenuButton = false, showLogos = false }) => {
  const { isLoggedIn, doLogin, doLogout, hasRole } = KeycloakService;
  const [openNav, setOpenNav] = useState(false)
  const sideBarLinks = [
    {
      name: "Hakkımızda",
      link: `${process.env.REACT_APP_BASE_URL}/${programName}`,
    },
    {
      name: "Etkinlik Takvimi",
      link: `${process.env.REACT_APP_BASE_URL}/${programName}/schedule`,
    },
    {
      name: "Start-up Portföyü",
      link: `${process.env.REACT_APP_BASE_URL}/${programName}/start-up-portfoyu`,
    },
    {
      name: "Geri Dönüşler",
      link: `${process.env.REACT_APP_BASE_URL}/${programName}/geri-donusler`,
      isDisabled: !isLoggedIn() || !hasRole([roles.ADMIN]),
    },
    {
      name: "Çıkış Yap",
      onClick: () => doLogout(),
      isBottom: true,
      isDisabled: !isLoggedIn(),
    },
    {
      name: "Kayıt Ol/Giriş Yap",
      onClick: () => doLogin(),
      isBottom: true,
      isDisabled: isLoggedIn(),
    },
  ]

  const sideMenuLinkItem = (sideBar, index) => (
    <div className="side-menu-link" key={index}>
      {sideBar.link && (
        <a
          href={sideBar.link}
          className="side-menu-link-text"
        >
          {sideBar.name}
        </a>
      )}
      {sideBar.onClick && (
        <p
          onClick={sideBar.onClick}
          className="side-menu-link-text"
        >
          {sideBar.name}
        </p>
      )}
    </div>
  )

  return (
    <div className="side-bar-container">
      <img
        className="hamburger-menu"
        src={isDarkMenuButton ? HamburgerMenu : HamburgerMenuLight}
        alt={"menu"}
        onClick={() => setOpenNav(!openNav)}
      />

      {showLogos && (
        <div className="main-logos">
          <img className="main-logo" src={ArinkomLogo} alt={"arinkom-logo"} />
          <img className="anac-logo" src={AnacLogo} alt={"anac-logo"} />
        </div>
      )}
      {openNav && (
        <div className="side-menu-wrapper">
          <div className="side-menu">
            <img
              src={CloseIcon}
              className="menu-close-button"
              onClick={() => setOpenNav(false)}
              alt={"close"}
            />
            <div className="side-menu-links">
              <div className="side-menu-top-links">
                {sideBarLinks
                  .filter(link => !link.isBottom && !link.isDisabled)
                  .map((sideBar, idx) => sideMenuLinkItem(sideBar, idx)
                )}
              </div>
              <div className="side-menu-bottom-links">
                {sideBarLinks
                  .filter(link => link.isBottom && !link.isDisabled)
                  .map((sideBar, idx) => sideMenuLinkItem(sideBar, idx)
                )}
              </div>
            </div>
          </div>
          <div className="side-menu-overlay" onClick={() => setOpenNav(!openNav)} />
        </div>
      )}
    </div>
  )
}

export default SideBar
