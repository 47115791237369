import React from 'react'
import RightArrowIcon from 'images/right-arrow.svg';
import "./ScrollUp.css";

const ScrollUp = () => {
  return (
    <div className="scroll-up-icon-wrapper"
      onClick={() => window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }>
      <img
        className="scroll-up-icon"
        src={RightArrowIcon}
        alt="scroll-up-icon"
      />
    </div>
  )
}

export default ScrollUp