import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import Header from "../Elements/Header/Header"
import { fetchFeedback } from "api/feedback"

import "./FeedbackData.css"

const FeedbackData = () => {
  const location = useLocation()
  const [feedback, setFeedback] = useState([])
  const [startup, setStartup] = useState([])

  const feedbackName = location.pathname.split("/")[4]

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: feedbacks } = await fetchFeedback()

        if (feedbacks.length) {
          const startupFeedbacks = feedbacks.filter(
            (feed) => feed.name === feedbackName
          )
          setStartup(startupFeedbacks[0].startup)
          setFeedback(startupFeedbacks[0])
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [feedbackName])

  return (
    <div className="feedback-data-container">
      <Header startUp={startup} />

      <div className="feedback-page-section">
        <form>
          <div className="feedback-form-inputs">
            <label className="feedback-form-label">İsim:</label>
            <input
              required
              type="text"
              className="feedback-form-input feedback-input--short-text"
              value={feedback.name}
            />
          </div>
          <div className="feedback-form-inputs">
            <label className="feedback-form-label">Soyisim:</label>
            <input
              required
              type="text"
              className="feedback-form-input feedback-input--short-text"
              value={feedback.surname}
            />
          </div>
          <div className="feedback-form-inputs">
            <label className="feedback-form-label">Firma - Kurum:</label>
            <input
              required
              type="text"
              className="feedback-form-input feedback-input--short-text"
              value={feedback.company}
            />
          </div>
          <div className="feedback-form-inputs">
            <label className="feedback-form-label">E-posta:</label>
            <input
              required
              type="email"
              className="feedback-form-input feedback-input--short-text"
              value={feedback.email}
            />
          </div>
          <div className="feedback-form-inputs">
            <label className="feedback-form-label">Telefon:</label>
            <input
              required
              type="tel"
              className="feedback-form-input feedback-input--short-text"
              value={feedback.phone}
            />
          </div>
          <div className="feedback-inputs-checkbox">
            <label className="feedback-label-checkbox">
              Yatırım yapmak istiyorum
            </label>
            <input
              type="checkbox"
              className="feedback-check-box"
              value={feedback.wantsToInvest}
              checked={feedback.wantsToInvest}
            />
          </div>
          <div className="feedback-inputs-checkbox">
            <label className="feedback-label-checkbox">
              Ürün ya da hizmet almak istiyorum
            </label>
            <input
              type="checkbox"
              className="feedback-check-box"
              value={feedback.wantsToBuyProduct}
              checked={feedback.wantsToBuyProduct}
            />
          </div>
          <div className="feedback-inputs-checkbox">
            <label className="feedback-label-checkbox">
              Destek / mentörlük vermek istiyorum
            </label>
            <input
              type="checkbox"
              className="feedback-check-box"
              value={feedback.wantsToMentor}
              checked={feedback.wantsToMentor}
            />
          </div>
          <div className="feedback-form-inputs">
            <label className="feedback-form-label">Görüş ve önerileriniz</label>
            <textarea
              className="feedback-form-input"
              value={feedback.suggestions}
              rows="10"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default FeedbackData
