import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchProgram } from 'api/program';
import ArchisLogo from 'images/archis-logo-dark.svg';
import SideBar from 'components/Elements/SideBar/SideBar';
import "./Program.css";

const Program = () => {
  const location = useLocation();
  const programName = location.pathname.split('/')[1];
  const [program, setProgram] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: programData } = await fetchProgram(programName);

        if (programData.length) {
          setProgram(programData[0]);
        }

      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }

    fetchData();
  }, [programName]);

  return (
    program && !isLoading && (
      <div className={"program-container"}>
        <SideBar
          programName={programName}
          isDarkMenuButton
          showLogos
        />
        {program.logo && (
          <div className="program-logo-wrapper">
            <img
              className="program-logo"
              src={program.logo.url}
              alt={program.name}
            />
          </div>
        )}
        <p className="program-title">{`${program.name} Nedir?`}</p>
        <p className="program-description">{program.description}</p>
        <div className="program-button-section">
          <button className="program-button">
            <a href={`${process.env.REACT_APP_BASE_URL}/${programName}/schedule`} className="program-button-link">
              Demo Day Programı
            </a>
          </button>
        </div>
        <div className="sponser-section">
          <p className="sponser-title">Supported by</p>
          <img src={ArchisLogo} alt={"archis-logo"} className="archis-logo" />
        </div>
      </div>
    )
  )
}

export default Program
