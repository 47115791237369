import { Route, Switch } from "react-router-dom"
import Program from "components/Program/Program"
import StartUps from "components/StartUps/StartUps"
import DetailedPage from "components/DetailedPage/DetailedPage"
import ConnectForm from "components/ConnectForm/ConnectForm"
import HomePage from "components/Home/HomePage"
import Feedback from "components/Feedback/Feedback"
import StartUpRegisterForm from "components/SignUpForm/StartUpRegisterForm"
import FeedbackDetails from "components/Feedback/FeedbackDetails"
import FeedbackData from "components/Feedback/FeedbackData"
import RolesRoute from "components/Auth/RolesRoute"
import roles from "components/utils/roles"
import "./App.css"

function App() {
  return (
    <div className="app">
      <Switch>
        <Route exact path="/:programName/start-up-portfoyu/:startUpName/connect">
          <ConnectForm />
        </Route>
        <Route exact path="/:programName/start-up-portfoyu/:startUpName">
          <DetailedPage />
        </Route>
        <Route exact path="/:programName/start-up-portfoyu">
          <StartUps />
        </Route>
        <Route exact path="/:programName/schedule">
          <HomePage />
        </Route>
        <RolesRoute
          exact
          path="/:programName/startup-register"
          roles={[roles.ADMIN]}
        >
          <StartUpRegisterForm />
        </RolesRoute>
        <RolesRoute
          exact
          path="/:programName/start-up-portfoyu/:startUpName/edit"
          roles={[roles.ADMIN]}
        >
          <StartUpRegisterForm isEdit />
        </RolesRoute>
        <Route exact path="/:programName">
          <Program />
        </Route>
        <RolesRoute exact path="/:programName/geri-donusler" roles={[roles.ADMIN]}>
          <Feedback />
        </RolesRoute>
        <RolesRoute
          exact
          path="/:programName/geri-donusler/:startUpName"
          roles={[roles.ADMIN]}
        >
          <FeedbackDetails />
        </RolesRoute>
        <RolesRoute
          exact
          path="/:programName/geri-donusler/:startUpName/:feedbackName"
          roles={[roles.ADMIN]}
        >
          <FeedbackData />
        </RolesRoute>
      </Switch>
    </div>
  )
}

export default App
