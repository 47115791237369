import React from "react"
import moment from "moment"

import "./FeedbackBox.css"

const FeedbackBox = ({ name, surname, email, phoneNumber, dateTime }) => {
  const date = moment(dateTime).format("DD/MM/YYYY")
  const time = moment(dateTime).format("HH:mm")

  return (
    <div className="feedback-details-box">
      <div className="feedback-details-box-content">
        <p>
          <span className="feedback-box-content-title">Name: </span>
          <span className="feedback-box-content-value">{name}</span>
        </p>
        <p>
          <span className="feedback-box-content-title">Surame: </span>
          <span className="feedback-box-content-value">{surname}</span>
        </p>
        <p>
          <span className="feedback-box-content-title">Email: </span>
          <span className="feedback-box-content-value">{email}</span>
        </p>
        <p>
          <span className="feedback-box-content-title">Telefon: </span>
          <span className="feedback-box-content-value">{phoneNumber}</span>
        </p>
      </div>
      <div className="feedback-box-date-time">
        <p className="feedback-details-box-date">{date}</p>
        <p className="feedback-details-box-time">{time}</p>
      </div>
    </div>
  )
}

export default FeedbackBox
