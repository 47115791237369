import axios from "axios"

// create an axios instance
const cms = axios.create({
  baseURL: process.env.REACT_APP_CMS_URL,
})

cms.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
)

const errorHandler = (error) => {
  return Promise.reject({ ...error })
}

export { cms }
