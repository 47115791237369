import React from "react"
import PropTypes from "prop-types"
import Facebook from "images/facebook.svg"
import Instagram from "images/instagram.svg"
import LinkedIn from "images/linkedin.svg"
import "./Footer.css"

const Footer = ({ startUp }) => {
  const socialMediaLinks = [
    {
      name: "facebook",
      url: startUp?.facebook,
      icon: Facebook,
    },
    {
      name: "instagram",
      url: startUp?.instagram,
      icon: Instagram,
    },
    {
      name: "linkedin",
      url: startUp?.linkedin,
      icon: LinkedIn,
    },
  ]

  return (
    <div className="footer-section">
      <div className="footer-icons">
        {socialMediaLinks.map((socialMedia, index) => (
          <a
            href={socialMedia?.url}
            key={index}
            className="footer-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={socialMedia?.icon} alt={`${socialMedia.name}-icon`} />
          </a>
        ))}
      </div>
    </div>
  )
}

Footer.propTypes = {
  startUp: PropTypes.object,
}

export default Footer
