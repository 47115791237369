import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { fetchStartUp } from '../../api/startup';
import Header from "components/Elements/Header/Header";
import Footer from "components/Elements/Footer/Footer";
import LinkIcon from 'images/link-icon.svg';
import ScrollUp from "components/Elements/ScrollUp/ScrollUp";

import "./DetailedPage.css"

const DetailedPage = () => {
  const location = useLocation()
  const startUpName = location.pathname.split("/")[3]
  const [startUp, setStartUp] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0)
    const fetchData = async () => {
      try {
        const { data: startUpData } = await fetchStartUp(startUpName)

        if (startUpData) {
          setStartUp(startUpData[0])
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()
  }, [startUpName])

  return (
    <>
      <div className="start-ups-container">
        <Header startUp={startUp} />
        <div className="detailed-page-section">
          <div>
            <p className="title">{startUp.name}</p>
            <p className="sub-title">{startUp.category || "Ed-Tech"}</p>
          </div>
          <div className="detailed-text">
            <p>{startUp.description}</p>
            <div className="link-text">
              <img src={LinkIcon} alt="link-icon"/>
              <a className="link-url" href={startUp.website} target="_blank" rel="noreferrer">
                {startUp.website}
              </a>
            </div>
          </div>
          <div className="content-section">
            <p className="title">Problem</p>
            <div className="about-text">
              {startUp.problem}
            </div>
          </div>
          <div className="content-section">
            <p className="title">Çözüm / Fayda</p>
            <div className="about-text">
              {startUp.solution}
            </div>
          </div>
          <div className="content-section">
            <p className="title">İş / Gelir Modeli</p>
            <div className="about-text">
              {startUp.incomeModel}
            </div>
          </div>
          <div className="content-section">
            <p className="title">Pazar / Müşteri</p>
            <div className="about-text">
              {startUp.targetMarket}
            </div>
          </div>
          <div className="content-section">
            <p className="title">Rekabet</p>
            <div className="about-text">
              {startUp.competitors}
            </div>
          </div>
          <div className="content-section">
            <p className="title">Ekip</p>
            <div className="member-list">
              {startUp.teamMembers}
            </div>
          </div>
          <div className="content-section">
            <p className="title">Finansman İhtiyacı</p>
            <div className="price-details">
              {startUp.financialNeeds}
            </div>
          </div>
          <div className="content-section">
            <p className="title">Ürün Hizmet Görseli</p>
            {startUp.productServiceImage && (
              <div className="laptop-image-wrapper">
                <img
                  src={startUp.productServiceImage.url}
                  className={"product-service-image"}
                  alt="laptop-icon"
                />
              </div>
            )}
          </div>
          {startUp.customers && (
            <div className="content-section">
              <p className="title">Destekler / Ödüller</p>
              <div className="about-text">
                {startUp.customers}
              </div>
            </div>
          )}
          <div className="content-section">
            <p className="title">Başarılar</p>
            <div className="collaborators-section">
              {startUp.events && startUp.events.map((event, index) => (
                <img
                  key={index}
                  className="event-icon"
                  src={event.url}
                  alt={`event-${index}`}
                />
              ))}
            </div>
          </div>
          <div className="content-section">
            <p className="title">İletişim</p>
            <div className="contact-text">
              {startUp.contact}
            </div>
          </div>
          <button className="connect-button">
            <a href={`${startUpName}/connect`} className="connect-button-link">
              İletişime Geç
            </a>
          </button>
        </div>
        <ScrollUp />
        <Footer startUp={startUp} />
      </div>
    </>
  )
}

export default DetailedPage
